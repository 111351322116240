<template>
  <FormWrapper
    :form="form"
    :hideForm="hideForm"
    :handleOpened="() => handleProps({ key: 'opened', value: !form.opened })"
    attachModule="informacoesAdicionaisAnamnese"
  >
    <b-row>
      <b-col>
        <div class="form-group mb-0">
          <TextArea
            id="informacoes-adicionais-anamnese-text-area"
            rows="1"
            autocomplete="off"
            :value="form.fields.informacoesAdicionaisAnamnese"
            :readonly="!canEdit"
            @input="value => updateForm('informacoesAdicionaisAnamnese', value)"
            @blur="updateMedicalRecord(form)"
            type="text"
            class="form-control"
            placeholder="Adicione outras informações relevantes"
          />
        </div>
      </b-col>
    </b-row>
  </FormWrapper>
</template>

<script>
import { mapState, mapActions } from 'vuex';
import FormWrapper from '@/components/Attendance/Forms/FormWrapper'
import TextArea from '@/components/General/TextArea'

export default {
  components: { FormWrapper, TextArea },
  computed: {
    ...mapState({
      canEdit: state => state.attendance.canEdit,
      form: state => state.attendance.form.informacoesAdicionaisAnamnese,
    })
  },
  methods: {
    ...mapActions('attendance/form', ['updateMedicalRecord', 'destroyMedicalRecord']),
    ...mapActions('attendance/form/informacoesAdicionaisAnamnese', ['handleFields', 'handleProps']),
    updateForm(key, value) {
      this.handleFields({ key, value })
    },
    hideForm(){
      this.handleProps({ key: 'showing', value: false })
      this.destroyMedicalRecord(this.form)
      Object.keys(this.form.fields).forEach(key => {
        this.form.fields[key] = null
      });
    }
  }
}
</script>
